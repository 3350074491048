

//本地环境
// export const baseUrl = "http://localhost:8070"

//本地环境
export const baseUrl = "https://yangsf.cn:8070"


//头像上传接口
export const uploadAvatarUrl = baseUrl + "/upload/uploadAvatar";

//图片上传接口
export const localUploadUrl = baseUrl + "/upload/localUpload";


export const toolbars = {
    'bold': true,
    'italic': true,
    'header': true,
    'underline': true,
    'strikethrough': true,
    'mark': true,
    'superscript': true,
    'subscript': true,
    'quote': true,
    'ol': true,
    'ul': true,
    'link': true,
    'imagelink': true,
    'code': true,
    'table': true,
    'undo': true,
    'redo': true,
    'trash': true,
    'save': false,
    'alignleft': true,
    'aligncenter': true,
    'alignright': true,
    'navigation': true,
    'subfield': true,
    'fullscreen': false,
    'readmodel': false,
    'htmlcode': true,
    'help': true,
    'preview': true
};
