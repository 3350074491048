import axios from 'axios'
import { baseUrl } from './config.js'
import router from "@/router";

import ViewUIPlus from 'view-ui-plus';

import useUserStore from "@/store/index";


const service = axios.create({
    // 环境变量，需要在.env文件中配置
    baseURL: baseUrl,
    // 超时时间暂定5s
    timeout: 5000,
});

// 请求拦截器
service.interceptors.request.use( config => {
  config.data = config.data || {}
  // 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
  const store = useUserStore()
  var tokenName = store.userInfo.tokenName;
  var tokenValue = store.userInfo.tokenValue;
  //console.log('发送请求tokenName ==> {}',tokenName);
  //console.log('发送请求tokenValue ==> {}',tokenValue);
  if (tokenName && tokenValue) {
    config.headers[tokenName] = tokenValue
  }

  ViewUIPlus.LoadingBar.start();
  return config
},
error => {
  return Promise.error(error)
})
  
// 返回拦截器
service.interceptors.response.use( res => {
      ViewUIPlus.LoadingBar.finish();
      //console.log('返回拦截器 res ==>{}', res);
      const back_data = res.data
      if (back_data.code === -1){
        ViewUIPlus.Message.error(back_data.msg)
        router.push('/login')
      }
      else if (back_data.code !== 200){
        if(back_data.msg){
          ViewUIPlus.Message.error(back_data.msg)
        }
        else{
          ViewUIPlus.Message.error("操作失败")
        }
      }
      return Promise.resolve(back_data);
    }, 
    error => {
      ViewUIPlus.LoadingBar.finish();
      //console.log('返回拦截器 error ==>{}', error);
      //console.log('返回拦截器 error.code ==>{}', error.code);
      if(error.code === 'ERR_NETWORK'){
        ViewUIPlus.Message.error({
          content: "oh! 请求出错了",
          duration: 10
        })
        router.push('/500')
      }

      switch (error.status) {
        case 500:
            ViewUIPlus.Message.error("系统错误")
            break
        case 404:
            ViewUIPlus.Message.error('Oops, Not Find!')
            break
        case 400:
            ViewUIPlus.Message.error('Oops, 400!')
            break
        // 其他错误，直接抛出错误提示
        default:
            ViewUIPlus.Message.error(error.message)
      }
      return Promise.reject(error);
    }
);


export default service;