import { defineStore } from 'pinia'

const useUserStore = defineStore('user',{ 
  state: () => ({
    isLogin: false,
    userInfo: {},
  }),
  getters: {
    getIsLogin() {
      return this.isLogin
    },
    getUser() {
      return this.userInfo
    },
  },
  actions: {
    setIsLogin(value) {
      this.isLogin = value
    },
    userLogin(value){
      this.isLogin=true
      this.userInfo = value
    },
    userLogout(){
      this.isLogin= false
      this.userInfo = {}
    }
  },
  persist: {
    enabled: true, // true 表示开启持久化保存
    strategies: [{
      key: 'userInfo',
      storage: localStorage, //可以选择对应的存储形式（localStorage或者sessionStroage）
    }]
  }
})
export default useUserStore
