import http from './request.js'
import qs from 'qs';

//post 单个参数提交 设置请求头 header:{'content-type':'application/x-www-form-urlencoded'}

const oneParamsHeader = {
    'content-type':'application/x-www-form-urlencoded'
}

const jsonHeader = {
    'content-type':'application/json;charset=utf-8'
}

const formDataHeader = {
    'content-type':'multipart/form-data'
}

//获取首页
export const getIndex = (params, config = {}) => http.post('/index', params, config)

//获取图像验证码
export const getCaptcha = (params, config = {oneParamsHeader}) => http.post('/getCaptcha', params, config)

//获取验证码
export const getCode = (params, config = {oneParamsHeader}) => http.post('/sms/getCode', qs.stringify(params), config)


export const getAboutMe = (params, config = {}) => http.post('/getAboutMe', params, config)

//在线工具
export const getTools = (params, config = {jsonHeader}) => http.post('/webSite/getTools', params, config)
//在线工具 - 根据分类获取
export const getWebSites = (params, config = {oneParamsHeader}) => http.post('/webSite/getWebSites', qs.stringify(params), config)

//用户登录
export const doLogin = (params, config = {jsonHeader}) => http.post('/doLogin', params, config)
//退出登录
export const logout = (params, config = {}) => http.post('/logout', params, config)

//获取用户收藏 - 树形结构
export const getWebTree = (params, config = {}) => http.post('/webSite/getWebTree', params, config) 

//获取网站图标
export const getFaviconUrl = (params, config = {oneParamsHeader}) => http.post('/getFaviconUrl', qs.stringify(params), config) 

//保存网站站点类型
export const saveCode = (params, config = {oneParamsHeader}) => http.post('/code/saveCode', qs.stringify(params), config) 

//保存网站站点信息
export const saveWebsite = (params, config = {oneParamsHeader}) => http.post('/webSite/saveWebsite', qs.stringify(params), config) 
export const webDelete = (params, config = {oneParamsHeader}) => http.post('/webSite/del', qs.stringify(params), config) 


//获取码表配置信息
export const getSysCodeList = (params, config = {oneParamsHeader}) => http.post('/code/getList', qs.stringify(params), config)


//保存博客
export const saveBlog = (params, config = {}) => http.post('/blog/save', params, config)

//获取博客列表
export const getBlogs = (params, config = {}) => http.post('/blog/getList', params, config)
//获取我的博客列表
export const getMyBlogs = (params, config = {}) => http.post('/blog/getMyList', params, config)
//查看博客
export const seeBlog = (params, config = {oneParamsHeader}) => http.post('/blog/see', qs.stringify(params), config)
//获取博客信息
export const getBlog = (params, config = {oneParamsHeader}) => http.post('/blog/get', qs.stringify(params), config)

//个人中心-获取菜单
export const getMenuTree = (params, config = {}) => http.post('/user/getMenuTree', params, config)

//个人中心-获取用户信息
export const getUserInfo = (params, config = {}) => http.post('/user/getUserInfo', params, config)

//个人中心-修改用户信息
export const setUserInfo = (params, config = {jsonHeader}) => http.post('/user/setUserInfo', params, config)

//个人中心-修改用户密码
export const changePassword = (params, config = {oneParamsHeader}) => http.post('/user/setPwd', qs.stringify(params), config)

//文件上传
export const uploadFile = (params, config = {formDataHeader}) => http.post('/upload/localUpload', params, config)
