<template>
  <router-view></router-view>
</template>

<style lang="less">
#app {
  height: 100vh;
  width: 100%;
}
</style>
