import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './style.css'

const app = createApp(App);

import ViewUIPlus from 'view-ui-plus'
import 'view-ui-plus/dist/styles/viewuiplus.css'
app.use(ViewUIPlus)
ViewUIPlus.Message.config({
    top: 100,
    duration: 3
});

import { createPinia } from 'pinia';
import piniaPersist from 'pinia-plugin-persist'
const pinia = createPinia();
pinia.use(piniaPersist)


import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'
app.use(mavonEditor);

app.use(pinia);
app.use(router)

app.mount('#app')
