/* eslint-disable no-undef */
import { createRouter, createWebHistory } from 'vue-router'
import IndexLayout from '@/views/layout/Layout.vue'
// import MyBlogLayout from '@/views/blog/myBlogs.vue'

const routes = [
  {
    path:"/",
    component: IndexLayout,
    redirect: '/tools',
    children:[
      {
        path: 'tools',
        name: 'tools',
        component: () => import('@/views/tools.vue')
      },
      {
        path: 'blogs',
        name: 'blogs',
        component: () => import('@/views/blogs.vue')
      },
      {
        path: 'blog/:id',
        name: 'blog',
        component: () => import('@/views/blog.vue')
      },
    //   {
    //     path: 'aiRoles',
    //     name: 'aiRoles',
    //     component: () => import('@/views/aiChat/aiRoles.vue')
    //   },
    //   {
    //     path: 'myAiRole',
    //     name: 'myAiRole',
    //     component: () => import('@/views/aiChat/myAiRole.vue')
    //   },
      {
        path: 'websites',
        name: 'websites',
        component: () => import('@/views/tools/website.vue'),
      },
      {
        path: 'userCenter',
        name: 'userCenter',
        component: () => import('@/views/userCenter/userCenter.vue'),
        children:[{
            path: 'userMsg',
            name: 'userMsg',
            component: () => import('@/views/userCenter/userMsg.vue')
        },{
            path: 'changePassword',
            name:'changePassword',
            component: () => import('@/views/userCenter/changePassword.vue')
        },
        // {
        //     path: 'customTheme',
        //     name:'customTheme',
        //     component: () => import('@/views/userCenter/customTheme.vue')
        // },
        {
            path: 'addBlog',
            name: 'addBlog',
            component: () => import('@/views/blog/addBlog.vue')
        },
        {
            path: 'userBlog/:id',
            name: 'userBlog',
            component: () => import('@/views/blog/userBlog.vue')
        },
        {
            path: 'myBlogs',
            name: 'myBlogs',
            component: () => import('@/views/blog/myBlogs.vue')
        },
        {
            path: 'myBlog/:id',
            name: 'myBlog',
            component: () => import('@/views/blog/blog.vue')
        }
        ]
      }
    ]
  },
  
  {
    path:"/403",
    name: '403',
    component: () => import('@/views/err/403.vue')
  },
  {
    path:"/404",
    name: '404',
    component: () => import('@/views/err/404.vue')
  },
  {
    path:"/500",
    name: '500',
    component: () => import('@/views/err/500.vue')
  },
  {
    path:"/login",
    name: 'login',
    component: () => import('@/views/Login.vue')
  },
  {
    path: '/:catchAll(.*)',
    redirect: "/403"
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


export default router
