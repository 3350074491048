<template>
    <div class="layout">
        <Layout>
            <Header :style="{position: 'fixed',zIndex: '9999', width: '100%', }">
                <Menu mode="horizontal" theme="dark" active-name="1">
                    <div class="layout-logo ysf-flex">
                        <!-- <Avatar :src="logoUrl" /> -->
                        <router-link to="/" style="color: #f1f1f1;">
                            <span style="font-size: 20px; position: relative;top: -15px;left: 10px;">{{ blogTitle
                                }}</span>
                        </router-link>
                    </div>
                    <div class="ysf-flex-row-justify-flex-end" style="{width: 100%;}">
                        <MenuItem name="1" to="/tools">
                        <Avatar size="18" shape="square" src="https://yangsf.cn/static/blog/tools1.png" />
                        <span class="ysf-font-size-18 ysf-m-l-5">在线工具</span>
                        </MenuItem>
                        <MenuItem name="21" to="/blogs">
                            <Avatar size="22" shape="square" src="https://yangsf.cn/static/blog/blog.png" />
                            <span class="ysf-font-size-18 ysf-m-l-5">博客</span>
                        </MenuItem>
                        <!-- <MenuItem name="31" to="/aiRoles">
                            <Avatar size="18" shape="square" src="https://yangsf.cn/static/blog/ai.png" />
                            <span class="ysf-font-size-18 ysf-m-l-5">友伴</span>
                        </MenuItem> -->
                        <!-- <MenuItem name="31" to="/aiRoles">
                            <Avatar shape="square" src="https://yangsf.cn/static/blog/ai_icon.png" />
                            AI虚拟人
                        </MenuItem> -->
                        <MenuItem v-if="!store.isLogin" name="4" to="/login">
                        登录 | 注册
                        </MenuItem>
                        <Submenu v-else name="4">
                            <template #title>
                                <Avatar :src="store.userInfo.avatar" />
                                <span class="ysf-m-l-10 ysf-m-r-5">{{
                                    store.userInfo.userName?store.userInfo.userName:store.userInfo.nickName }}</span>
                            </template>
                            <MenuItem name="3-1" to="/userCenter">个人中心</MenuItem>
                            <MenuItem name="3-2" to="/websites">网站管理</MenuItem>
                            <!-- <MenuItem name="3-3" to="/myAiRole">我的友伴</MenuItem> -->
                            <MenuItem name="3-4" @click="userLogout">退出登录</MenuItem>
                        </Submenu>
                    </div>
                    <div class="layout-login">
                    </div>

                </Menu>
            </Header>
            <Content class="layout-content ysf-scrollbar-y">
                <router-view></router-view>
            </Content>
            <Footer class="layout-footer-center">
                <span class="myCopyright" v-if="myCopyright">Copyright &copy; {{ myCopyright }}</span>
                <span class="ysf-m-l-20" v-if="myICP">ICP备案/许可证号:
                    <a href="https://beian.miit.gov.cn/" target="_blank">
                        {{ myICP }}
                        <Icon type="ios-arrow-forward" />
                    </a>
                </span>
            </Footer>
        </Layout>
    </div>
</template>

<script>
export default {
  name: 'MyLayout',
}
</script>

<script setup>
  import { getIndex,logout } from "@/config/api.js";
  import { ref } from "vue";
  import useUserStore from "@/store/index";
    import router from "@/router";

  let blogTitle = ref("JinBoot生活日志")
  let myCopyright = ref("JinBoot生活日志 All Rights Reserved.");
  let myICP = ref("豫ICP备20003581号-1");
  let logoUrl = ref("http://img.yangshifeng.cn/myLogo.png")

  const store = useUserStore()

  index();
  function index(){
    getIndex().then(res =>{
        blogTitle.value = res.data.blogTitle;
        myCopyright.value = res.data.myCopyright;
        myICP.value = res.data.myICP
        logoUrl.value = res.data.logoUrl
    })
  }

  function userLogout(){
        logout().then(res =>{
            store.userLogout();
            router.push("/login")
        })
  }
</script>

<style scoped>
.layout{
    /* border: 1px solid #d7dde4; */
    background: #f5f7f9;
    position: relative;
    border-radius: 4px;
    overflow: hidden;
}
.layout-logo{
    width: 200px;
    height: 30px;
    /* background: #515A6E; */
    border-radius: 3px;
    float: left;
    position: relative;
    top: 15px;
    left: 20px;
}
</style>